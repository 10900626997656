import PropTypes from 'prop-types';
import React from 'react';
import Title from '../../molecules/title/title';
import { Wrapper } from '../../utils/components';
import { getCommonOrganismData } from '../../utils/functions';
import withPreview from '../../utils/with-preview';
import classNames from 'classnames';

import './Timeline.scss';
import AnchorHooking from '../../molecules/anchor-hooking/AnchorHooking';
import Icon from '../../atoms/icon';
import ContenutoHtmlInner from '../../molecules/helpers-contenuto-html/ContenutoHtmlInner';

const Timeline = ({ data, considerMenuSpace }) => {
  const {
    anchorLabel,
    backgroundColor,
    htmlTagWrapper,
    marginTop,
    title,
    titleHeadingTag,
    titleIconId,
    titleIconThickness,
  } = getCommonOrganismData(data);

  const introductionText = data?.introductionText?.value;

  const timelineItem = data?.timelineItem;

  const timelineItemsToRender = () => {
    return timelineItem.map((item, index) => {
      return (
        <li key={index} className='timeline__item'>
          {item?.content?.iconSvgId?.value ? (
            <Icon
              icon={item?.content?.iconSvgId?.value}
              thickness={
                item?.content?.iconSvgId?.content?.iconThicknessTimelineItem
                  ?.value[0]
              }
              className='timeline__item-label timeline__item-label--icon'
            />
          ) : (
            <p className='timeline__item-label'>{item?.value}</p>
          )}
          <div className='box-editorial timeline__item-description'>
            <ContenutoHtmlInner
              rawContent={item?.content?.descrizione?.value}
            />
          </div>
        </li>
      );
    });
  };

  return (
    <Wrapper
      tag={htmlTagWrapper}
      data-module-name='timeline'
      data-module-code='C-32'
      className={classNames('timeline wrapper', marginTop)}
    >
      <AnchorHooking id={anchorLabel} belowMenu={considerMenuSpace} />
      {(title || titleIconId) && (
        <Title
          tag={titleHeadingTag}
          titleIconId={titleIconId}
          titleIconThickness={titleIconThickness}
        >
          {title}
        </Title>
      )}
      <div
        style={{ '--bg': `var(--${backgroundColor})` }}
        className={classNames('timeline__wrapper', {
          'timeline__wrapper--colored-background':
            backgroundColor !== 'transparent',
        })}
      >
        {introductionText && (
          <span className='timeline__introduction'>{introductionText}</span>
        )}
        <ul className='timeline__list'>{timelineItemsToRender()}</ul>
      </div>
    </Wrapper>
  );
};

Timeline.propTypes = {
  data: PropTypes.object,
};

export default withPreview(Timeline);
